import React from "react"
import humanizeDuration from "humanize-duration"
import { Link } from "gatsby"

import ProfilePicture from "../images/face.jpg"
import styles from "./meta.module.css"

export default ({ date, timeToRead, includePicture = true }) => {
  const minutesString = humanizeDuration(timeToRead * 60000, {
    round: true,
    units: ["m"],
  })

  let name = "Maciej Winnicki"
  if (includePicture) {
    name = (
      <Link className={styles.link} to="/about">
        Maciej Winnicki
      </Link>
    )
  }

  return (
    <div className={styles.wrapper}>
      {includePicture && (
        <Link className={styles.link} to="/about">
          <img
            src={ProfilePicture}
            alt="Maciej Winnicki"
            className={styles.avatar}
          />
        </Link>
      )}
      <small className={styles.meta}>
        {name} · {date} · {minutesString} read
      </small>
    </div>
  )
}
